$color-primary: hsl(31, 100%, 50%);
$color-secondary: hsl(42, 100%, 44%);
$color-tertiary: hsl(321, 100%, 50%);
$color-text: hsl(0, 100%, 27%);
$color-text-highlight: hsl(0, 84%, 41%);
$color-link: $color-tertiary;
$color-invert: white;

$sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$serif: Georgia, serif;
$monospace: 'Nimbus Mono PS', 'Courier New', monospace;

@import './assets/styles/reset';

html {
	font-size: 10px;
	min-height: 100vh;
}

body {
	background-color: $color-primary;
	background-image: linear-gradient(100deg, $color-primary 0%, $color-primary 10%, $color-secondary 30%, $color-tertiary 100%);
	background-size: 100%;
	color: $color-text;
	font-family: $sans;
	font-size: 1.8rem;
	height: 100%;
	line-height: 1.44;
}

.triviaContainer {
	// background-color: rgba(0,0,0, 0.25);
	background-color: rgba(255,255,255, 0.38);
	box-shadow: 0 0 24px 4px rgba(0,0,0,0.2);
	margin-left: auto;
	margin-right: auto;
	max-width: 640px;
	min-height: 100vh;
	padding: 6rem 3rem;
}

.siteHeader {
	align-items: flex-end;
	display: flex;
	gap: 20px;
	margin-bottom: 4rem;
}

.primaryHeading {
	font-size: 4.8rem;
	font-weight: 700;
	letter-spacing: -0.01em;
}

.siteHeader-logo {
	max-width: 100px;
	width: 100%;

	svg {
		display: block;
		height: auto;
		width: 100%;
	}

}

.triviaForm {
	font-size: 2.4rem;
	position: relative;
}

.triviaQuestion {
	margin-bottom: 1em;
	position: relative;
}

.triviaQuestionPart {

	// &::after {
	// 	// background-color: white;
	// 	bottom: -0.2em;
	// 	color: $color-invert;
	// 	content: attr(data-underscores);
	// 	display: block;
	// 	// height: 2px;
	// 	height: 100%;
	// 	left: 0;
	// 	opacity: 0.5;
	// 	pointer-events: none;
	// 	position: absolute;
	// 	width: 100%;
	// }

}

.triviaAnswer-underscores {
	bottom: -0.2em;
	color: $color-invert;
	display: block;
	font-family: inherit;
	// font-weight: bold;
	// height: 2px;
	height: 100%;
	left: 0;
	letter-spacing: 0.11em;
	opacity: 0.5;
	pointer-events: none;
	// position: absolute;
	width: fit-content;
}

.triviaQuestionPart--answer {
	display: inline-block;
	font-family: $monospace;
	position: relative;
}

.triviaQuestionLabel {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	z-index: 1;
}

.triviaAnswer {
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	color: $color-text-highlight;
	display: block;
	font-family: inherit;
	font-size: inherit;
	font-weight: bold;
	height: 1em;
	// letter-spacing: 0.04em;
	letter-spacing: 0.1em;
	// max-width: 260px;
	opacity: 1;
	// position: relative;
	position: absolute;
	left: 0;
	// right: 0;
	top: 0;
	width: 100%;
	z-index: 2;

	&::placeholder {
		color: $color-text-highlight;
		font-family: inherit;
		letter-spacing: inherit;
		opacity: 0;
	}
}

.triviaFormFooter {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
}

.triviaFormSubmit {
	appearance: none;
	align-items: center;
	border-radius: 1em;
	border: 4px solid rgba(255,255,255,0.6);
	background: transparent;
	color: inherit;
	cursor: pointer;
	display: inline-flex;
	font-size: inherit;
	font-weight: 700;
	gap: 0.5em;
	padding: .5em .5em .5em 0.75em;
	transition: border-color 150ms, background-color 150ms, color 150ms;

	&::after {
		content: '';
		background-color: $color-invert;
		border-radius: 50%;
		display: block;
		font-size: inherit;
		height: .5em;
		width: .5em;
		opacity: 0.6;
		transition: opacity 150ms;
	}

	&:not(:disabled):hover {
		border-color: rgba(255,255,255,1);
		background-color: $color-link;
		color: $color-invert;

		&::after {
			opacity: 1;
		}
	}

	&:disabled {
		color: rgba(255,255,255,0.6);
		cursor: default;
		opacity: .66;
	}

}

.triviaMeta {
	color: #333;
	font-style: italic;
	// margin-top: .5em;
	// padding-left: calc(0.75em + 4px);
}

.triviaAnswerList {
	margin-top: 1.4em;
	position: relative;

	&::before {
		content: 'Previous answers: ';
		font-size: .75em;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}
}

.triviaQuestion-loadingIndicator,
.triviaQuestion-loadingIndicator div {
  box-sizing: border-box;
}
.triviaQuestion-loadingIndicator {
  color: #ff00ff;
	display: none;
}
.triviaQuestion-loadingIndicator {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
  height: 80px;
  position: absolute;
  width: 80px;
	z-index: 2;

	div {
		animation: triviaQuestion-loadingIndicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: currentColor transparent transparent transparent;
		border-radius: 50%;
		border: 8px solid currentColor;
		box-sizing: border-box;
		display: block;
		height: 64px;
		margin: 8px;
		position: absolute;
		width: 64px;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}

		&:nth-child(2) {
			animation-delay: -0.3s;
		}

		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}

}

@keyframes triviaQuestion-loadingIndicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.triviaForm--gotCorrectAnswer {

	.triviaMeta {
		display: none;
	}

	.triviaAnswerItem:last-child {
		color: green;

		&::before {
			content: '✓ ';
		}
	}

	.triviaFormSubmit {
		color: green;
		border-color: green;
	}

}

.triviaForm--is-loading {

	.triviaQuestion-loadingIndicator {
		display: inline-block;
	}

	.triviaAnswerList,
	.triviaMeta {
		display: none;
	}

	.triviaQuestion {
		animation-name: fadeInOut;
		animation-duration: .75s;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-timing-function: ease-in-out;
		min-height: 6em;
    background-color: white;
    border-radius: .5em;
	}

}

@keyframes fadeInOut {
  0% {
    opacity: .25;
  }
  100% {
    opacity: .5;
  }
}
